export class Address{

    constructor(data){
        this.id = data?.id;
        this.contactName = data?.street?.contactName;
        this.streetName = data?.street?.streetName;
        this.house = data?.street?.house || null;
        this.apartment = data?.street?.apartment || null;
        this.city = data?.area?.city;
        this.state = data?.area?.state || null;
        this.country = data?.area?.country;
        this.zipcode = data?.area?.zipcode;
        this.isBilling = data?.isBilling || false;
        this.isShipping = data?.isShipping || false;
    }

    formatStreet(){
        let street = "";
        if(this.house) street += `${this.house} `;
        street += this.streetName;
        if(this.apartment) street += `apt. ${this.apartment}`;
        return street;
    }

    formatArea(){
        let area = this.city;
        if(this.state) area += ` ${this.state}`;
        area += `, ${this.country}, ${this.zipcode}`;
        return area;
    }
}