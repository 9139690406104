import React from "react";
import '../../App.css';
import {Alert, Button, Container, Form, Input, Label} from "reactstrap";
import {Link} from "react-router-dom";
import withRouter from "../withRouter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark, faEdit} from '@fortawesome/free-solid-svg-icons';

class UpdatePasswordForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            passwordForm: { oldPassword: "", password: "", confirmPassword: "" },
            csrfToken: document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
            alertOpen: true,
            hasError: false,
            error: { code: null, message: null }
        };
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDismiss(){
        this.setState({ alertOpen: false })
    }

    handleChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let passwordForm = {...this.state.passwordForm };
        passwordForm[name] = value;
        this.setState({ passwordForm });
    }

    async handleSubmit(event){
        event.preventDefault();
        const { passwordForm, csrfToken } = this.state;
        const response = await fetch("/api/accounts/updatePassword", {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-XSRF-TOKEN" : csrfToken
            },
            body: JSON.stringify(passwordForm)
        });
        if(response.status !== 200){
            const error = await response.json();
            this.setState({ hasError: true, error: { name: `${error.statusCode} ${error.statusText}`, message: error.message } });
            return;
        }
        this.props.router.navigate("/accounts");
    }

    handleError(hasError, error){
        if(!hasError) return <div id="statusMessage"/>
        let messages = error.message.split("<br/>")
        return <div id="statusMessage" className="text-center text-danger">
            {error.name || "500 Server Error"}<br/>
            {messages.map((message, index) => <div key={"error" + index}>{message}<br/></div>)}
        </div>
    }

    render(){
        const { passwordForm, alertOpen, hasError, error } = this.state
        const errorMessage = this.handleError(hasError, error);
        return (<div id="updatePassword">
            <h2>Change Password on BetaRill.com</h2>
            {errorMessage}
            <Container>
                <Form onSubmit={this.handleSubmit}>
                    <Alert color="warning" isOpen={alertOpen} toggle={this.handleDismiss}>
                        This page allows you to change your password.<br/>
                        Valid quantity of characters for your password is up to 128 characters, and may contain letters, numbers, and special characters. <br/>
                        It is recommended that you use a special character, such as * or ! to make your password more secure. <br/>
                    </Alert>
                    <div className="mb-3">To change your password, enter your current password, and your new password in the form below:</div>
                    <div className="mb-3 row">
                        <div className="col-sm-4">
                            <Label for="oldPassword">Current Password:</Label>
                            <Input type="password" id="oldPassword" name="oldPassword" maxLength="128"
                                   value={passwordForm.oldPassword || ""} onChange={this.handleChange} required={true} />
                        </div>
                        <div className="col-sm-4">
                            <Label for="password">New Password:</Label>
                            <Input type="password" id="password" name="password" maxLength="128"
                                   value={passwordForm.password || ""} onChange={this.handleChange} required={true} />
                        </div>
                        <div className="col-sm-4">
                            <Label for="confirmPassword">Confirm Password:</Label>
                            <Input type="password" id="confirmPassword" name="confirmPassword" maxLength="128"
                                   value={passwordForm.confirmPassword || ""} onChange={this.handleChange} required={true} />
                        </div>
                    </div>
                    <div className="mb-3 text-center">
                        <Button type="submit" color="warning">
                            <FontAwesomeIcon icon={faEdit} /> Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="text-white" color="secondary" tag={Link} to="/accounts">
                            <FontAwesomeIcon icon={faCircleXmark} /> Cancel
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>)
    }
}

export default withRouter(UpdatePasswordForm);