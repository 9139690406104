import React from "react";
import '../../App.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Container} from "reactstrap";
import {Link, Outlet} from "react-router-dom";
import {faEnvelope, faLock, faMapMarkerAlt, faQuestionCircle, faUserCog, faUserEdit, faUserFriends} from '@fortawesome/free-solid-svg-icons'
import withRouter from "../withRouter";
import ReactTooltip from "react-tooltip";

class Accounts extends React.Component{

    atIndex(){
        return this.props.router.location.pathname === "/accounts"
    }

    render(){
        if(!this.atIndex()) return <Outlet />;
        return <div id="accounts">
            <h2><FontAwesomeIcon icon={faUserCog}/> Account Settings</h2>
            <p>Below are a list of actions you can do to manage your account: </p>
            <Container>
                <ul className="fa-ul">
                    <li>
                        <Link to="/accounts/password"><FontAwesomeIcon icon={faLock} listItem />Change Password</Link>
                    </li>
                    <li>
                        <Link to="/accounts/email"><FontAwesomeIcon icon={faEnvelope} listItem />Change Email Information</Link>
                    </li>
                    <li>
                        <Link to="/accounts/addresses">
                            <FontAwesomeIcon icon={faMapMarkerAlt} listItem />
                            Manage Address <FontAwesomeIcon icon={faQuestionCircle} data-tip="Manage your detailed location, this info is necessary for sellers to ship physical goods to you" />
                            <ReactTooltip />
                        </Link>
                    </li>
                    <li>
                        <Link to="/users/edit"><FontAwesomeIcon icon={faUserEdit} listItem />Edit User Profile</Link>
                    </li>
                    <li>
                        <Link to="/contacts"><FontAwesomeIcon icon={faUserFriends} listItem />Manage Contact List</Link>
                    </li>
                </ul>
            </Container>
        </div>
    }
}

export default withRouter(Accounts);

