import React from "react";
import '../../App.css';
import {Address} from "../../viewmodels/Address";
import {Button, ButtonGroup} from "reactstrap";
import {Link} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCirclePlus, faEdit, faFileInvoiceDollar, faTriangleExclamation, faTruckFast} from '@fortawesome/free-solid-svg-icons'

export default class AddressList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            addresses: [],
            csrfToken: document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
            hasError: false,
            error: { code: null, message: null }
        };
        this.remove = this.remove.bind(this);
    }

    async componentDidMount(){
        this.setState({ isLoading: true});
        const response = await fetch("/api/addresses");
        if(response.status !== 200){
            this.setState({ isLoading: false, hasError: true, error: { code: response.status, message: response.statusText } });
            return;
        }
        const addresses = await response.json();
        this.setState({ isLoading: false, addresses: addresses } );
    }

    async remove(id){
        const response = await fetch(`/api/address/${id}`, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-XSRF-TOKEN" : this.state.csrfToken
            }
        });
        if(response.status !== 200){
            this.setState({ hasError: true, error: { code: response.status, message: response.statusText } });
            return;
        }
        const updatedAddresses = [...this.state.addresses].filter(address => address.id !== id);
        this.setState({ addresses: updatedAddresses });
    }

    render() {
        const { addresses, isLoading, hasError, error } = this.state
        if(isLoading){
            return <p>Loading...</p>;
        }
        const errorMessage = hasError ? <div className="text-center text-danger">{error.code}: {error.message}</div> : "";

        const addressList = addresses.map(address => new Address(address));
        return (
            <div className="addressList">
                <h2 id="h2title">Your Addresses</h2>
                {errorMessage}
                <p>Below is a list of addresses associated with your account:</p>
                <div className="row mx-3">
                    {addressList?.map(address =>
                        <div id={"address" + address.id} className="border col-12 col-md-6 py-2" key={address.id}>
                            <div className="row">
                                <div className="col-8 col-sm-9">
                                    {address.contactName}
                                </div>
                                <div className="col-4 col-sm-3 text-end">
                                    {address.isBilling ? <FontAwesomeIcon icon={faFileInvoiceDollar} data-tip="This is your default billing address" /> : ""}
                                    &nbsp;
                                    {address.isShipping ? <FontAwesomeIcon icon={faTruckFast} data-tip="This is your default shipping address" /> : ""}
                                    <ReactTooltip />
                                </div>
                            </div>
                            {address.formatStreet()} <br/>
                            {address.formatArea()} <br/>
                            <ButtonGroup>
                                <Button className="text-dark" size="sm" color="warning" tag={Link} to={"/accounts/addresses/" + address.id}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button size="sm" color="danger" onClick={() => this.remove(address.id)}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} /> Delete
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
                <div className="my-3 text-center">
                    <Button className="text-white" color="success" tag={Link} to="/accounts/addresses/new">
                        <FontAwesomeIcon icon={faCirclePlus} /> Add Address
                    </Button>
                </div>
            </div>
        );
    }
}