import React from "react";
import {Route} from 'react-router-dom';
import Accounts from "../components/accounts/Accounts";
import UpdatePasswordForm from "../components/accounts/UpdatePasswordForm";
import UpdateEmailForm from "../components/accounts/UpdateEmailForm";
import AddressList from "../components/accounts/AddressList";
import AddressForm from "../components/accounts/AddressForm";

const accountsRoutes = (
    <Route path="accounts" element={<Accounts />}>
        <Route index element={<Accounts />} />
        <Route path="password" element={<UpdatePasswordForm />} />
        <Route path="email" element={<UpdateEmailForm />} />
        <Route path="addresses" element={<AddressList />} />
        <Route path="addresses/:id" element={<AddressForm />} />
    </Route>
);

export default accountsRoutes