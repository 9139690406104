import React from "react";
import './App.css'
import {Button, Collapse, Form, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeadset, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";

export default class Menu extends React.Component{

    constructor(props){
        super(props);
        this.state = { isOpen: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle(){
        this.setState({ isOpen: !this.state.isOpen });
    }

    contactUs(){
        return <NavItem className="mx-3">
            <NavLink className="text-white" href="/home/contacts" data-tip="Eastern/New York timezone">
                <FontAwesomeIcon icon={faHeadset}/> 10am - 6pm
            </NavLink>
        </NavItem>;
    }

    render() {
        const currentUser = this.props.currentUser;
        let userbar = <Nav className="mx-auto" navbar>{this.contactUs()}</Nav>;
        if(currentUser){
            userbar = <Nav className="mx-auto" navbar>
                <NavItem className="mx-3">
                    <NavLink className="text-white" tag={Link} to="/users/browse">
                        Hello, {currentUser.name}
                    </NavLink>
                </NavItem>
                <NavItem className="mx-3 pt-2">
                    <Form inline action="/accounts/logout" method="post">
                        <Button type="submit" className="text-white mb-0" size="sm" color="danger">
                            <b><FontAwesomeIcon icon={faRightFromBracket}/> Logout</b>
                        </Button>
                    </Form>
                </NavItem>
                {this.contactUs()}
            </Nav>;
        }

        return <Navbar expand="lg" fixed="top" style={{ backgroundColor: "blue", fontSize: "20px", paddingTop: "1px", paddingBottom: "1px" }}>
            <NavbarBrand className="mx-2 text-yellow" tag={Link} to="/">
                <b className="text-lg">BetaRill</b> <span id="betarill-slogan" className="text-xs">- Sell, Provide, Write!</span>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse id="navbarSupportedContent" isOpen={this.state.isOpen} navbar>
                <Nav className="mx-auto" navbar>
                    {userbar}
                </Nav>
            </Collapse>
        </Navbar>;
    }
}