import React from "react";
import '../../App.css';
import withRouter from "../withRouter";
import {Button, Container, Form, Input, Label} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faEdit} from "@fortawesome/free-solid-svg-icons";

class UpdateEmailForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            emailForm: { email: "", confirmEmail: ""},
            csrfToken: document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
            hasError: false,
            error: { code: null, message: null }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let emailForm = {...this.state.emailForm };
        emailForm[name] = value;
        this.setState({ emailForm });
    }

    async handleSubmit(event){
        event.preventDefault();
        const { emailForm, csrfToken } = this.state;
        const response = await fetch("/api/accounts/updateEmail", {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-XSRF-TOKEN" : csrfToken
            },
            body: JSON.stringify(emailForm)
        });
        if(response.status !== 200){
            const error = await response.json();
            if(response.status === 401) setTimeout(() => window.location = "/accounts/login", 2000);
            this.setState({ hasError: true, error: { name: `${error.statusCode} ${error.statusText}`, message: error.message } });
            return;
        }
        this.props.router.navigate("/accounts");
    }

    handleError(hasError, error){
        if(!hasError) return <div id="statusMessage"/>
        let messages = error.message.split("<br/>")
        return <div id="statusMessage" className="text-center text-danger">
            {error.name || "500 Server Error"}<br/>
            {messages.map((message, index) => <div key={"error" + index}>{message}<br/></div>)}
        </div>
    }

    render(){
        const { emailForm, hasError, error } = this.state
        const errorMessage = this.handleError(hasError, error);
        return (<div id="updateEmail">
            <h2>Change Email on BetaRill.com</h2>
            {errorMessage}
            <Container>
                <div className="mb-3">This page allows you to change your email address. </div>
                <div className="mb-3">To change your email address simply type your new email address in the box below: </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="mb-3 row">
                        <div className="col-sm-6">
                            <Label for="email">New Email:</Label>
                            <Input type="email" id="email" name="email" maxLength="128"
                                   value={emailForm.email || ""} onChange={this.handleChange} required={true} />
                        </div>
                        <div className="col-sm-6">
                            <Label for="confirmEmail">Confirm Email:</Label>
                            <Input type="email" id="confirmEmail" name="confirmEmail" maxLength="128"
                                   value={emailForm.confirmEmail || ""} onChange={this.handleChange} required={true} />
                        </div>
                    </div>
                    <div className="mb-3 text-center">
                        <Button type="submit" color="warning">
                            <FontAwesomeIcon icon={faEdit} /> Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="text-white" color="secondary" tag={Link} to="/accounts">
                            <FontAwesomeIcon icon={faCircleXmark} /> Cancel
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>)
    }
}

export default withRouter(UpdateEmailForm)