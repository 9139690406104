import React from "react";
import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import Home from "./components/Home";
import Layout from "./Layout";
import accountsRoutes from "./routes/AccountsRoutes";

class App extends React.Component{

    state = {
        currentUser: null,
        hasError: false,
        error: { name: null, message: null }
    };

    async componentDidMount(){
        let currentUser = await this.findCurrentUser();
        this.setState({ currentUser: currentUser });
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
        this.setState({
            hasError: true,
            error: {
                name: error.name,
                message: error.message
            }
        })
    }

    async findCurrentUser(){
        let response = await fetch("/api/accounts/findAuthenticatedUser");
        let currentUser = await response.json();
        if(response.status !== 200){
            setTimeout(() => window.location = `${window.location.protocol}//${window.location.hostname}:8080/accounts/login`);
            this.setState({ hasError: true, error: { name: `${currentUser.statusCode} ${currentUser.statusText}`, message: currentUser.message } });
            return null;
        }
        return currentUser;
    }

    render(){
        const { hasError, error } = this.state
        if (hasError){
            return <p className="text-center text-danger">{error.name}: {error.message}</p>
        }

        return (
            <Routes>
                <Route path="/" element={<Layout currentUser={this.state.currentUser} />}>
                    <Route index element={<Home />} />
                    {accountsRoutes}
                </Route>
            </Routes>
        );
    }
}

export default App;