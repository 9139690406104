import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

export default class Home extends Component{
    render() {
        return (
            <div id="homepage">
                <h2>Welcome to BetaRill</h2>
                <Container fluid>
                    <Button color="link"><Link to="/accounts">Manage your account</Link></Button>
                </Container>
            </div>
        );
    }
}