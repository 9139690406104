import React from "react";
import Menu from "./Menu";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";

export default class Layout extends React.Component{

    render() {
        return (
            <div id="bodycontent">
                {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
                <Menu currentUser={this.props.currentUser} />
                {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
                <Outlet />
                <Footer />
            </div>
        );
    }
}
