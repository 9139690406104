import React from "react";
import '../../App.css'
import {Address} from "../../viewmodels/Address";
import {Button, Container, Form, Input, Label} from "reactstrap";
import {Link} from "react-router-dom";
import withRouter from "../withRouter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faCircleXmark} from '@fortawesome/free-solid-svg-icons'

class AddressForm extends React.Component{

    constructor(props){
        super(props);
        this.state = { address: new Address(), csrfToken: document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1') };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
        if(this.props.router.params.id !== "new"){
            const response = await fetch(`/api/address/${this.props.router.params.id}`);
            const address = await response.json();
            this.setState({ address: new Address(address) });
        }
    }

    handleChange(event){
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let address = {...this.state.address };
        address[name] = value;
        this.setState({ address });
    }

    async handleSubmit(event){
        event.preventDefault();
        const { address } = this.state;
        await fetch("/api/address" + (address.id ? "/" + address.id : ""), {
            method: (address.id) ? "PUT" : "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-XSRF-TOKEN" : this.state.csrfToken
            },
            body: JSON.stringify(address)
        });
        this.props.router.navigate("/accounts/addresses");
    }

    render() {
        const { address } = this.state;
        const title = <h2>{address.id ? "Edit Address" : "Add Address"}</h2>
        return <Container>
            {title}
            <Form onSubmit={this.handleSubmit}>
                <div className="mb-3 row">
                    <div className="col-sm-4">
                        <Label for="contactName">Contact Name: </Label>
                        <Input type="text" id="contactName" name="contactName" placeholder="Your contact name" maxLength="50"
                               value={address.contactName || ""} onChange={this.handleChange} required={true} />
                    </div>
                    <div className="col-sm-4">
                        <Label for="streetName">Street Name: </Label>
                        <Input type="text" id="streetName" name="streetName" placeholder="Your street name" maxLength="50"
                               value={address.streetName || ""} onChange={this.handleChange} required={true} />
                    </div>
                    <div className="col-sm-2">
                        <Label for="house">House: </Label>
                        <Input type="text" id="house" name="house" placeholder="Your house #" maxLength="5"
                               value={address.house || ""} onChange={this.handleChange} />
                    </div>
                    <div className="col-sm-2">
                        <Label for="apartment">Apt: </Label>
                        <Input type="text" id="apartment" name="apartment" placeholder="Your apt #" maxLength="5"
                               value={address.apartment || ""} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <Label for="city">City: </Label>
                        <Input type="text" id="city" name="city" placeholder="The city of your address" maxLength="20"
                               value={address.city || ""} onChange={this.handleChange} required={true} />
                    </div>
                    <div className="col-sm-6">
                        <Label for="state">State: </Label>
                        <Input type="text" id="state" name="state" placeholder="The state of your address" maxLength="20"
                               value={address.state || ""} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <Label for="country">Country: </Label>
                        <Input type="text" id="country" name="country" placeholder="The country of your address" maxLength="20"
                               value={address.country || ""} onChange={this.handleChange} required={true} />
                    </div>
                    <div className="col-sm-6">
                        <Label for="zipcode">Zipcode: </Label>
                        <Input type="text" id="zipcode" name="zipcode" placeholder="Your zipcode" maxLength="10"
                               value={address.zipcode || ""} onChange={this.handleChange} required={true} />
                    </div>
                </div>
                <div className="mb-0">
                    <div className="form-check">
                        <Input type="checkbox" id="isBilling" name="isBilling" checked={address.isBilling} onChange={this.handleChange} />
                        <Label className="form-check-label" for="isBilling">Use as Billing Address</Label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <Input type="checkbox" id="isShipping" name="isShipping" checked={address.isShipping} onChange={this.handleChange} />
                        <Label className="form-check-label" for="isShipping">Use as Shipping Address</Label>
                    </div>
                </div>
                <div className="mb-3 text-center">
                    <Button type="submit" color={ address.id ? "warning" : "success"}>
                        <FontAwesomeIcon icon={faCircleCheck} /> Save
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="text-white" color="secondary" tag={Link} to="/accounts/addresses">
                        <FontAwesomeIcon icon={faCircleXmark} /> Cancel
                    </Button>
                </div>
            </Form>
        </Container>
    }
}

export default withRouter(AddressForm)